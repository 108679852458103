import React from "react";
import "./App.scss";
import "react-modern-drawer/dist/index.css";
import { useSearchParams } from "react-router-dom";
import Builder from "./components/Builder/Builder";
import { ToastContainer } from "react-toastify";

const App: React.FC<any> = () => {
	let [searchParams] = useSearchParams();

	React.useEffect(() => {});
	const service = serviceDetector(searchParams);
	const title = titleDetector(searchParams, service);
	return (
		<>
			<Builder fromShare={title} />
			<ToastContainer
				position="top-right"
				autoClose={7000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={true}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

const serviceDetector = (urlParams: any) => {
	let title = urlParams.get("title");
	let text = urlParams.get("text");
	let merged = title + "___" + text;
	const services = ["spotify", "deezer", "soundclound"];

	let res = services.filter((a) => merged.indexOf(a) !== -1);

	if (res?.length) return res[0];
	return "";
};

const titleDetector = (params: any, service: string) => {
	switch (service) {
		case "deezer": {
			return {
				type: "auto",
				service: "deezer",
				title: params.get("title"),
			};
		}
		case "spotify": {
			let text = params.get("text");
			let res = text.match(/(track|album|artist)\/(.*)\?/);
			if (!res.length) return false;
			return {
				type: res[1],
				id: res[2],
				service: "spotify",
				endpoint: res[1] + "/" + res[2],
			};
		}
	}
};

export default App;
