import React, { useRef, useState } from "react";
import "./Toolbar.scss";
import { getDefaultConf, IState } from "../../Builder";
import ToolbarItems, { getTool } from "../Tools/Tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import { usePopper } from "react-popper";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ITool } from "../Tools/Tools";
import { t } from "i18next";
import { customAlert } from "../../../Toasts/AlertToast";

export interface IToolbar {
	state: IState;
	setState: (vals: IState) => void;
	isActive?: boolean;
	setDrawerState?: any;
	drawerState?: any;
}

export interface IToolbarProps {
	state: IToolbar;
	tool: ITool;
	onClick?: any;
	isActive?: boolean;
}

interface IToolbarPopper extends IToolbarProps {
	isOpen: boolean;
	close: any;
	refElem: any;
}

const Toolbar: React.FC<IToolbar> = (props) => {
	const [CurTool, setCurTool] = useState<ITool | undefined>(
		document.body.clientWidth > 1000 ? getTool("bgColor") : undefined
	);
	const refElem = useRef<any>(null);

	const handleClick = (tool: ITool) => {
		if (CurTool?.id && tool.id === CurTool?.id)
			return setCurTool(undefined);
		if (tool.component) setCurTool(tool);
		if (document.body.clientWidth < 1050)
			props.setDrawerState({
				children: false,
				isOpen: false,
			});
	};

	return (
		<div
			className="Toolbar z-10 flex gap-6 md:gap-5 items-center bg-white dark:bg-neutral-900 drop-shadow-sm shadow-sm"
			ref={refElem}
		>
			<ToolbarBtn
				tool={{
					id: "search",
					title: t("Search.search"),
					icon: faSearch,
					onClick: () => {
						props.setDrawerState({
							...props.drawerState,
							isOpen: !props.drawerState.isOpen,
						});
					},
				}}
				state={{
					state: props.state,
					setState: props.setState,
				}}
			/>
			{ToolbarItems().map((a: any, b: number) => {
				return (
					<ToolbarBtn
						key={b}
						tool={a}
						state={{
							state: props.state,
							setState: props.setState,
						}}
						isActive={CurTool?.id === a.id}
						onClick={() => handleClick(a)}
					/>
				);
			})}
			{CurTool && CurTool.popper && (
				<ToolbarPopper
					state={{
						state: props.state,
						setState: props.setState,
					}}
					refElem={refElem}
					isOpen={true}
					tool={CurTool}
					close={() => setCurTool(undefined)}
				/>
			)}
			<ToolbarBtn
				tool={{
					id: "reset",
					title: t("Tools.reset"),
					icon: faTrash,
					onClick: () => {
						customAlert({
							onOk: () => {
								localStorage.clear();
								props.setState(getDefaultConf());
							},
							toastId: "reset",
						});
					},
				}}
				state={{
					state: props.state,
					setState: props.setState,
				}}
			/>
			<Tooltip id="toolbar-tooltip" offset={15} />
		</div>
	);
};

const ToolbarPopper: React.FC<IToolbarPopper> = (props) => {
	const { state, setState } = props.state;
	const { refElem } = props;
	const [popperElement, setPopperElement] = useState<any>(null);
	const { styles, attributes } = usePopper(refElem.current, popperElement, {
		placement: "auto",
		strategy: "fixed",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [0, 20],
				},
			},
		],
	});

	useOutsideAlerter(refElem, () => {
		if (document.body.clientWidth < 1000) props.close();
	});

	return (
		<div
			ref={setPopperElement}
			style={styles.popper}
			{...attributes.popper}
			className="ToolbarPopper popup_tadbs bg-black"
		>
			<props.tool.popper
				state={state}
				setState={setState}
			></props.tool.popper>
		</div>
	);
};

const ToolbarBtn: React.FC<IToolbarProps> = (props) => {
	const { state, tool } = props;
	const classList = [
		"button",
		"text-black dark:text-white",
		(props.isActive && "active") || "",
		tool.className,
	].join(" ");

	const handleClick = () => {
		if (tool.onClick) return tool.onClick(state.state);
		if (tool.component && props.onClick) return props.onClick();
	};

	return (
		<button
			className={classList}
			data-tooltip-id="toolbar-tooltip"
			data-tooltip-content={tool.component ? "" : tool.title}
			onClick={handleClick}
		>
			{tool.component ? (
				<tool.component
					isActive={props.isActive}
					state={state.state}
					setState={state.setState}
				></tool.component>
			) : tool.icon ? (
				<FontAwesomeIcon icon={tool.icon} />
			) : (
				tool.title
			)}
		</button>
	);
};

export default Toolbar;
