import React, { useContext } from "react";
import { IToolbar } from "../Toolbar/Toolbar";
import GPicker from "../GPicker/GPicker";
import { ThemeContext } from "../../../../contexts/Theme/ThemeContext";
import { ThemeContextType } from "../../../../contexts/Theme/types";
import { t } from "i18next";
import Tabs from "../../../Tabs/Tabs";
import FontSelector from "../../../FontSelector/FontSelector";
import { IFont } from "../../../FontSelector/fontLoader";
import Slider from "../../../Slider/Slider";

export interface IFontConfig {
	fontFamily?: string;
	fontSize?: string;
	fontWeight?: string;
	letterSpacing?: string;
	lineHeight?: string;
}

const TextConfig: React.FC<IToolbar> = (props) => {
	const { state } = props;

	let style: any = {};
	if (state.textColor?.indexOf("gradient") === -1)
		style.backgroundColor = state.textColor;
	else style.backgroundImage = state.textColor;

	return (
		<div
			className="TextColor"
			style={{
				height: "100%",
				width: "20px",
			}}
		>
			<div
				data-tooltip-id="toolbar-tooltip"
				data-tooltip-content={
					props.isActive ? "" : t("Tools.textColor").toString()
				}
				className="flex color-preview rounded-full aspect-square items-center justify-center outline outline-1 outline-neutral-300 dark:outline-neutral-700"
				style={style}
			>
				{" "}
				<span
					className="text-white"
					style={{
						fontFamily: "Times",
						lineHeight: "10px",
						fontSize: "10px",
						mixBlendMode: "exclusion",
					}}
				>
					T
				</span>
			</div>
		</div>
	);
};

export const TextColorPopper: React.FC<IToolbar> = (props) => {
	const { state, setState } = props;

	const handleChange = (data: any) => {
		setState({ ...state, ...data });
	};

	return (
		<div className="dark:bg-neutral-900 rounded-md">
			<Tabs
				childs={[
					{
						id: "textColor",
						title: t("Text.color"),
						component: ColorConfig,
					},
					{
						id: "title",
						title: t("Text.title"),
						component: FontConfig,
						props: {
							stateProp: "titleProp",
							onChange: handleChange,
						},
					},
					{
						id: "text",
						title: t("Text.subtitle"),
						component: FontConfig,
						props: {
							stateProp: "textProp",
							onChange: handleChange,
						},
					},
				]}
				props={{
					state,
					setState,
				}}
			/>
		</div>
	);
};

const ColorConfig: React.FC<any> = (props: any) => {
	const { isDarkMode } = useContext<ThemeContextType>(ThemeContext);
	const { state, setState } = props;
	const handleColorChange = (val: string) => {
		setState({
			...state,
			textColor: val,
		});
	};

	return (
		<GPicker
			value={state.textColor || "white"}
			onChange={handleColorChange}
			gradient={false}
			theme={isDarkMode ? "dark" : ""}
		/>
	);
};

const FontConfig: React.FC<any> = (props: any) => {
	const { state, setState } = props;

	let text = state[props.stateProp];

	const handleFontChange = (prop: any, value: any) => {
		let n_state = {
			...state,
			[props.stateProp]: {
				...state[props.stateProp],
				[prop]: value,
			},
		};
		setState(n_state);
	};

	return (
		<div className="flex flex-col gap-4">
			<FontSelector
				initialValue={
					text?.fontFamily
						? {
								value: text.fontFamily,
								label: text.fontFamily,
						  }
						: undefined
				}
				onChange={(font: IFont) =>
					handleFontChange("fontFamily", font.value)
				}
			/>
			<Slider
				hidebreakpoint={1000}
				hideondrag={".ToolbarPopper"}
				title={t("Text.fontSize").toString()}
				value={text?.fontSize || 6}
				onChange={({ x }) => handleFontChange("fontSize", x.toFixed(2))}
				xmin={0.1}
				xmax={30}
				xstep={0.1}
			/>
			<Slider
				hidebreakpoint={1000}
				hideondrag={".ToolbarPopper"}
				title={t("Text.lineHeight").toString()}
				value={text?.lineHeight || 1}
				onChange={({ x }) =>
					handleFontChange("lineHeight", x.toFixed(2))
				}
				xstep={0.1}
				xmin={0.8}
				xmax={5}
			/>
			<Slider
				hidebreakpoint={1000}
				hideondrag={".ToolbarPopper"}
				title={t("Text.letterSpacing").toString()}
				value={text?.letterSpacing || 1}
				onChange={({ x }) => handleFontChange("letterSpacing", x)}
			/>
			<Slider
				hidebreakpoint={1000}
				hideondrag={".ToolbarPopper"}
				title={t("Text.fontWeight").toString()}
				value={text?.fontWeight || 400}
				onChange={({ x }) => handleFontChange("fontWeight", x)}
				xstep={100}
				xmin={100}
				xmax={900}
			/>
		</div>
	);
};

export default TextConfig;
