import { faSave } from "@fortawesome/free-solid-svg-icons";
import BackgroundConfig, {
	BackgroundPopper,
} from "./Background/BackgroundConfig";
import MusicTagConfig from "./MusicTagConfig";
import Save from "./Save";
import TextConfig, { TextColorPopper } from "./TextConfig";
import { t } from "i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ITool {
	id: string;
	title: string;
	component?: any;
	popper?: any;
	icon?: IconProp;
	className?: string;
	onClick?: any;
	use?: any;
	drawer?: any;
}

const ToolbarItems = () => [
	// function because i18n return undefined if it's just a const
	{
		id: "textColor",
		title: t("Tools.textColor"),
		component: TextConfig,
		popper: TextColorPopper,
	},
	{
		id: "bgColor",
		title: t("Tools.bgColor"),
		component: BackgroundConfig,
		popper: BackgroundPopper,
	},
	{
		id: "musicTag",
		title: t("Tools.musicTag"),
		component: MusicTagConfig,
		onClick: () => {},
	},
	{
		id: "saveBtn",
		className: "hover:text-neutral-600",
		title: t("Tools.save"),
		onClick: Save,
		icon: faSave,
	},
];

export const getTool = (toolName: string) => {
	let tool = ToolbarItems().find((t) => t.id === toolName);
	return tool ? tool : undefined;
};

export default ToolbarItems;
