import React from "react";
import "./Frame.scss";
import { IStateProps } from "../../Builder";
import Image from "./Image/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import MusicTag from "./MusicTag/MusicTag";
import { t } from "i18next";

const Frame: React.FC<IStateProps> = (props) => {
	const { state, setState } = props;
	let ref = React.createRef<any>();

	React.useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			handleResize();
			window.removeEventListener("resize", handleResize);
		};
	});

	const preview = document.querySelector(".Frame .preview");

	const bgOffset = () => {
		let x = 50 - 50 * (state.bgProps?.posX ?? 0);
		let y = 50 - 50 * (state.bgProps?.posY ?? 0);
		return `${x + "%"} ${y + "%"}`;
	};

	const handleResize = () => {
		let scale = ((window.innerHeight * 0.8) / 1980).toFixed(2);
		document.documentElement.style.setProperty("--frame-scale", scale);
		document.documentElement.style.setProperty(
			"--cover-bg-blur",
			(state.bgProps?.blur !== undefined ? state.bgProps.blur : 50) + "px"
		);
	};

	let bgStyle: any = {};

	if (state.bgType !== "image") {
		if (state.background?.indexOf("rgb") === 0)
			bgStyle.backgroundColor = state.background;
		else bgStyle.backgroundImage = state.background;
	} else
		bgStyle = {
			// backgroundImage: `url(${state.backgroundImg})`,
			// backgroundPosition: bgOffset(),
			// backgroundSize: `${
			// 	(preview?.clientHeight || 1980) *
			// 	(1 + (state.bgProps?.size || 0) / 100)
			// }px`,
		};

	let txtStyle: any = {};

	let isGradient = state.textColor?.indexOf("gradient") !== -1;

	if (state.textColor?.indexOf("gradient") === -1)
		txtStyle.color = state.textColor;
	else txtStyle.backgroundImage = state.textColor;

	return (
		<div className="Frame flex">
			<div
				ref={ref}
				className="preview flex flex-col items-center gap-8 group"
				style={bgStyle}
			>
				<button
					className="gen-hide flex gap-4 absolute top-6 left-6 z-10 text-4xl text-white bg-neutral-700 duration-500 bg-opacity-0 group-hover:bg-opacity-70 p-2 px-5 rounded-full"
					onClick={() => {
						setState({
							...state,
							isBgLocked: !state.isBgLocked,
						});
					}}
				>
					<FontAwesomeIcon
						icon={state.isBgLocked ? faLock : faLockOpen}
					/>
					{t(`Background.${state.isBgLocked ? "unlock" : "lock"}`)}
				</button>
				{state.bgType === "image" && (
					<div
						className="coverBg z-0"
						style={{
							backgroundImage: `url(${state.backgroundImg})`,
							backgroundPosition: bgOffset(),
							backgroundSize: `${
								(preview?.clientHeight || 1980) *
								(1 + (state.bgProps?.size ?? 0) / 100)
							}px`,
						}}
					/>
				)}
				<Image
					className="framed"
					onChange={(res: any) => {
						setState({
							...state,
							imgRotation: 0,
							image: res,
							backgroundImg: state.isBgLocked
								? state.backgroundImg
								: res,
							bgType: state.isBgLocked ? state.bgType : "image",
						});
					}}
					onLock={() =>
						setState({
							...state,
							isImgLocked: !state.isImgLocked,
						})
					}
					onRotate={() =>
						setState({
							...state,
							imgRotation: (state?.imgRotation || 0) + 90,
						})
					}
					onRemove={() => {
						setState({
							...state,
							image: "",
						});
					}}
					isLocked={state.isImgLocked}
					image={state.image}
					rotation={state.imgRotation}
				/>
				<div
					className={
						"flex flex-col items-center w-full infos text-center z-10" +
						(isGradient ? " gradient-txt" : "")
					}
					style={txtStyle}
				>
					<Textarea
						className="w-full title bg-transparent text-center"
						value={state.title}
						style={{
							...state.titleProp,
							fontSize: (state.titleProp?.fontSize || 6) + "rem",
						}}
						onChange={(e: any) => {
							setState({
								...state,
								title: e.target.value,
							});
						}}
						placeholder={t("State.title")}
						maxline={3}
					/>
					<Textarea
						className="w-full text bg-transparent text-center"
						value={state.text}
						onChange={(e: any) => {
							setState({
								...state,
								text: e.target.value,
							});
						}}
						style={{
							...state.textProp,
							fontSize: (state.textProp?.fontSize || 6) + "rem",
						}}
						lineheight={16 * 3.5}
						maxline={3}
						placeholder={t("State.text")}
					/>
				</div>
				<div
					style={txtStyle}
					className={
						"z-10 w-full my-auto infos" +
						(isGradient ? " gradient-txt" : "")
					}
				>
					<Textarea
						className="w-full text more bg-transparent text-center"
						value={state.more}
						onChange={(e: any) => {
							setState({
								...state,
								more: e.target.value,
							});
						}}
						lineheight={16 * 3.5}
						maxline={3}
						placeholder={t("State.more")}
					/>
				</div>
				{state.musicTag && (
					<MusicTag
						state={state}
						setState={setState}
						className="z-20"
					/>
				)}
				{/* <div
					className="border border-x-0 mt-auto z-10"
					style={{
						width: "10px",
						height: "8rem",
					}}
				></div> */}
			</div>
		</div>
	);
};

const Textarea = (props: any) => {
	const ref = React.useRef();
	const auto_grow = () => {
		let elem: any = ref?.current;
		if (!elem) return false;

		const numNewlines = elem.value.split("\n")?.length || 1;
		if (numNewlines > props.maxline) {
			let txt = elem.value.split("\n").slice(0, 3).join("\n");
			elem.value = txt;
		}
		const nbt = numNewlines * (props.lineheight || 16 * 6);
		elem.style.height = nbt + "px";

		if (elem.scrollHeight > nbt)
			elem.style.height = elem.scrollHeight + "px";
	};

	React.useEffect(() => {
		auto_grow();
	});

	return (
		<textarea
			ref={ref}
			onChangeCapture={auto_grow}
			{...props}
			style={{
				resize: "none",
				...props.style,
			}}
		/>
	);
};

export default Frame;
