import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const SearchBar: React.FC<any> = (props: any) => {
	const [Value, setValue] = useState("");

	const handleChange = (e: any) => {
		let val = e?.target?.value ?? Value;
		setValue(val);
		if (props.onChange) props.onChange(val);
	};

	const reset = () => {
		setValue("");
		if (props.onChange) props.onChange("");
	};
	return (
		<div className="flex justify-between items-center gap-2 w-full p-2 rounded-md bg-neutral-100 dark:bg-neutral-800">
			<input
				value={Value}
				{...props}
				onChange={handleChange}
				className="bg-transparent w-full outline-none"
			/>
			<button
				onClick={reset}
				className={
					"outline-none focus:outline-1 focus:outline-neutral-300 focus:dark:outline-neutral-700 outline rounded-md duration-300" +
					(Value?.length ? " opacity-100" : " opacity-0")
				}
			>
				<FontAwesomeIcon icon={faTimes} className="text-neutral-400" />
			</button>
		</div>
	);
};

export default SearchBar;
