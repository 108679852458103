import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-regular-svg-icons";
import { ThemeContextType } from "./types";
import { ThemeContext } from "./ThemeContext";
import { t } from "i18next";

const ThemeSwitcher: React.FC = (props) => {
	const { isDarkMode, toggleDarkMode } =
		useContext<ThemeContextType>(ThemeContext);

	return (
		<button
			onClick={toggleDarkMode}
			className="flex w-100 m-auto gap-2 py-1 items-center text-black hover:bg-neutral-100 dark:text-white hover:dark:bg-neutral-700 rounded-xl px-2 aspect-1"
		>
			<div className="text-sm">
				{t(`Theme.${isDarkMode ? "light" : "dark"}`)}
			</div>
			<FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
		</button>
	);
};

export default ThemeSwitcher;
