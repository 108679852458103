import GoogleFonts from "./GoogleFonts";

export interface IFont {
	label: string;
	value: string;
	distant?: boolean;
	weight?: string[];
}

export const FontFamillies: IFont[] = [
	{ value: "Arial", label: "Arial" },
	{ value: "Comic Sans MS", label: "Comic Sans MS" },
	{ value: "Courier New", label: "Courier New" },
	{ value: "Garamond", label: "Garamond" },
	{ value: "Georgia", label: "Georgia" },
	{ value: "Helvetica", label: "Helvetica" },
	{ value: "Palatino", label: "Palatino" },
	{ value: "Times New Roman", label: "Times New Roman" },
	{ value: "Trebuchet MS", label: "Trebuchet MS" },
	{ value: "Verdana", label: "Verdana" },
	...GoogleFonts,
].sort((a, b) => (a.label < b.label ? -1 : 1));
