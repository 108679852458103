import React from "react";
import { IStateProps } from "../../../Builder";
import "./MusicTag.scss";

interface IMusicTag extends IStateProps {
	className?: string;
}

const MusicTag: React.FC<IMusicTag> = (props) => {
	const { state } = props;

	const classList = [
		"MusicTag flex gap-7 w-70 bg-white items-center",
		props.className,
	].join(" ");

	let title = state.item?.title || state.title;
	let text = state.item?.text || state.text;
	let image = state.item?.image || state.image;
	return (
		<div className={classList}>
			<div
				className="image aspect-square rounded-sm"
				style={{
					backgroundImage: `url(${image})`,
				}}
			></div>
			{(title || text) && (
				<div className="w-full overflow-hidden flex flex-col pr-7">
					<span className="title">{title}</span>
					<span className="text">{text}</span>
				</div>
			)}
		</div>
	);
};

export default MusicTag;
