import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { useRef, useState } from "react";
import "./Search.scss";
import { SPOTIFY_API } from "../../../includes/paths";
import { t } from "i18next";
import SearchResult from "./SearchResult";
import SearchBar from "../../SearchBar/SearchBar";

interface ISearch {
	onItemClick: (val: any) => void;
	initialValue?: string;
	endpoint?: string;
	onClose?: any;
}

interface IRequest {
	isLoading: boolean;
	data: any;
	error: Error | boolean;
}

const Search: React.FC<ISearch> = (props) => {
	const player = useRef<any>(null);
	const isInitied = useRef<Boolean>(false);
	const [SongPreview, setSongPreview] = useState("");
	const timeout = useRef<any>(0);
	const [ReqState, setReqState] = useState<IRequest>({
		isLoading: false,
		data: false,
		error: false,
	});
	const [Search, setSearch] = useState(props.initialValue ?? "");

	React.useEffect(() => {
		if (!isInitied.current) {
			if (props.endpoint) enpointSearch(props.endpoint);
			else if (props.initialValue) search(props.initialValue);
		}
		isInitied.current = true;
	});

	const enpointSearch = (endpoint: string) => {
		setReqState({
			...ReqState,
			isLoading: true,
			error: false,
		});
		setSearch("");
		axios.get(SPOTIFY_API + endpoint, { timeout: 5000 }).then(
			(resp: any) => {
				setReqState({
					...ReqState,
					data: [],
					isLoading: false,
				});
				let data = resp?.data;
				if (!resp || !resp.data) return false;

				setSearch(resp.data.name);
				props.onItemClick({
					resp,
					type: data.type,
					title: data.name,
					text: (data.artists || data.genres)
						?.slice(0.3)
						.map((a: any) => a.name || a)
						.join(", "),
					image: data.album?.images[0].url || data.images[0].url,
				});
				if (props.onClose) props.onClose();
			},
			(e) => {
				setReqState({
					...ReqState,
					data: [],
					error: e,
					isLoading: false,
				});
			}
		);
	};

	const search = (src: any) => {
		setReqState({
			...ReqState,
			isLoading: true,
		});
		axios
			.get(SPOTIFY_API + encodeURIComponent(src), { timeout: 5000 })
			.then(
				(resp) =>
					setReqState({
						...ReqState,
						data: resp.data !== "OK" ? resp.data : false,
						isLoading: false,
						error: false,
					}),
				(e) => {
					setReqState({
						...ReqState,
						data: [],
						error: e,
						isLoading: false,
					});
				}
			);
	};

	const handleSearch = (term: string) => {
		setSearch(term);
		clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			search(term);
		}, 500);
	};

	const handlePreview = (value: string) => {
		if (value !== SongPreview) setSongPreview(value);
		else setSongPreview("");
	};

	const handleClick = (item: any) => {
		setSongPreview("");
		if (props.onItemClick) props.onItemClick(item);
	};

	return (
		<div className="Search flex flex-col gap-2 h-full overflow-hidden">
			{SongPreview?.length > 0 && (
				<audio
					ref={player}
					autoPlay
					src={SongPreview}
					onEnded={() => setSongPreview("")}
				/>
			)}
			<div className="">
				<div>{t("Search.search")}</div>
				<SearchBar
					value={Search}
					onChange={(val: string) => handleSearch(val)}
					placeholder={t("Search.ph").toString()}
				/>
			</div>
			<hr className="my-3 border-neutral-300 dark:border-neutral-600" />
			{ReqState.isLoading ? (
				<FontAwesomeIcon
					className="fa-spin text-neutral-700 dark:text-neutral-400"
					icon={faSpinner}
				/>
			) : ReqState.error ? (
				<div className="w-100 text-center text-red-500">
					{t("Search.error")}
				</div>
			) : (
				<SearchResults
					{...props}
					onItemClick={handleClick}
					data={ReqState.data}
					handlePreview={handlePreview}
					currentSong={SongPreview}
					term={Search}
				/>
			)}
		</div>
	);
};

interface ISrcRes extends ISearch {
	data: any;
	handlePreview: any;
	currentSong: any;
	term: string;
}

const NoResult = () => (
	<div className="w-full text-center text-neutral-500">
		{t("Search.noResult")}
	</div>
);

const SearchResults: React.FC<ISrcRes> = (props) => {
	const { data, handlePreview, currentSong, term } = props;

	const hasResults = () => {
		if (!data) return false;
		if (
			term.length > 0 &&
			(data.albums?.total > 0 ||
				data.artists?.total > 0 ||
				data.tracks?.total > 0)
		)
			return true;
		return false;
	};

	if (!hasResults() && term.length > 0) return <NoResult />;
	return (
		<div className="h-full overflow-auto">
			{data && (
				<ul className="flex flex-col gap-2">
					<li>
						{t("Search.track", {
							count: data.tracks?.total ?? 0,
						})}
					</li>
					{data.tracks?.total ? (
						data.tracks.items
							.slice(0, 10)
							.map((item: any, index: any) => (
								<SearchResult
									key={item.id + "-" + index}
									title={item.name}
									artist={item.artists
										.map((a: any) => a.name)
										.join(", ")}
									preview={
										item.preview_url
											? () =>
													handlePreview(
														item.preview_url
													)
											: false
									}
									isPlaying={currentSong === item.preview_url}
									imageUrl={item.album.images[0].url}
									onClick={() =>
										props.onItemClick({
											item,
											type: "track",
											title: item.name,
											text: item.artists
												.slice(0.3)
												.map((a: any) => a.name)
												.join(", "),
											image: item.album.images[0].url,
										})
									}
								/>
							))
					) : (
						<NoResult />
					)}
					<li className="mt-4">
						{t("Search.album", {
							count: data.albums?.total ?? 0,
						})}
					</li>
					{data.albums?.total ? (
						data.albums.items.slice(0, 5).map((item: any) => (
							<SearchResult
								key={item.id}
								title={item.name}
								imageUrl={item.images[0].url}
								artist={item.artists
									.map((a: any) => a.name)
									.join(", ")}
								onClick={() =>
									props.onItemClick({
										item,
										title: item.name,
										type: "album",
										text: item.artists
											.slice(0.3)
											.map((a: any) => a.name)
											.join(", "),
										image: item.images[0].url,
									})
								}
							/>
						))
					) : (
						<NoResult />
					)}
					<li className="mt-4">
						{t("Search.artist", {
							count: data.artists?.total ?? 0,
						})}
					</li>
					{data.artists?.total ? (
						data.artists.items.slice(0, 3).map((item: any) => (
							<SearchResult
								key={item.id}
								title={item.name}
								imageUrl={item.images[0]?.url}
								onClick={() =>
									props.onItemClick({
										item,
										type: "artist",
										title: item.name,
										text: "",
										image: item.images[0]?.url,
									})
								}
							/>
						))
					) : (
						<NoResult />
					)}
				</ul>
			)}
		</div>
	);
};

export default Search;
