import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import ThemeSwitcher from "../../contexts/Theme/ThemeSwitcher";
import useOutsideAlerter from "../Builder/hooks/useOutsideAlerter";
import LanguageSwitcher from "../../i18n/LanguageSwitcher/LanguageSwitcher";
import { t } from "i18next";

const Settings: React.FC = (props: any) => {
	const [isOpen, setIsOpen] = useState(false);
	const [referenceElement, setReferenceElement] = useState<any>(null);

	const [popperElement, setPopperElement] = useState<any>(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: "top",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [20, -20],
				},
			},
		],
	});

	return (
		<div>
			<button
				ref={setReferenceElement}
				title={t("settings").toString()}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					setIsOpen(!isOpen);
				}}
			>
				<FontAwesomeIcon icon={faCog} />
			</button>
			{isOpen && (
				<div
					ref={setPopperElement}
					style={{
						...styles.popper,
						minWidth: "200px",
					}}
					{...attributes.popper}
					className="relative z-50 popup_tadbs bg-gray-200 shadow-md dark:bg-neutral-900 rounded-md p-4"
				>
					<PopperBody close={() => setIsOpen(false)} />
				</div>
			)}
		</div>
	);
};

interface IPopper {
	close: any;
}

const PopperBody: React.FC<IPopper> = (props: any) => {
	const ref = useRef<any>();

	useOutsideAlerter(ref, (e: any) => {
		props.close();
	});

	return (
		<div
			ref={ref}
			className=" bg-neutral-200 dark:bg-neutral-900 rounded-md"
		>
			<div className="text-lg text-center w-full text-neutral-700 dark:text-neutral-500">
				{t("settings")}
			</div>
			<hr className="mt-2 mb-4 border-neutral-300 dark:border-neutral-800" />
			<LanguageSwitcher />
			<hr className="my-3 border-neutral-300 dark:border-neutral-800" />
			<ThemeSwitcher />
			<hr className="my-3 border-neutral-300 dark:border-neutral-800" />
			<div className="flex items-center justify-center gap-1 text-neutral-500 text-xs">
				<span>v{process.env.REACT_APP_VERSION}</span>
				<span>- 2023 ©</span>
				<span>
					<a
						href="https://shimos.fr"
						rel="noreferrer"
						target={"_blank"}
					>
						Shim'on
					</a>
				</span>
			</div>
		</div>
	);
};

export default Settings;
