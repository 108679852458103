const GoogleFonts = [
	{
		label: "Abril Fatface",
		value: "Abril Fatface",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Amatic SC",
		value: "Amatic SC",
		weight: ["400", "700"],
		distant: true,
	},
	{
		label: "Asset",
		value: "Asset",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Baloo",
		value: "Baloo",
		weight: ["400", "500", "700"],
		distant: true,
	},
	{
		label: "Bebas Neue",
		value: "Bebas Neue",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Bungee",
		value: "Bungee",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Cairo Play",
		value: "Cairo Play",
		weight: ["300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Caveat",
		value: "Caveat",
		weight: ["400", "500", "700"],
		distant: true,
	},
	{
		label: "Climate Crisis",
		value: "Climate Crisis",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Crimson Pro Italic",
		value: "Crimson Pro Italic",
		weight: ["300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Crimson Pro",
		value: "Crimson Pro",
		weight: ["300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Fascinate",
		value: "Fascinate",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Grand Hotel",
		value: "Grand Hotel",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Lato Italic",
		value: "Lato Italic",
		weight: ["100", "300", "400", "700", "900"],
		distant: true,
	},
	{
		label: "Lato",
		value: "Lato",
		weight: ["100", "300", "400", "700", "900"],
		distant: true,
	},
	{
		label: "Literata Italic",
		value: "Literata Italic",
		weight: ["300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Literata",
		value: "Literata",
		weight: ["300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Lobster",
		value: "Lobster",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Monoton",
		value: "Monoton",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Montserrat Italic",
		value: "Montserrat Italic",
		weight: ["100", "300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Montserrat",
		value: "Montserrat",
		weight: ["100", "300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Oswald",
		value: "Oswald",
		weight: ["300", "400", "500", "700"],
		distant: true,
	},
	{
		label: "Pacifico",
		value: "Pacifico",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Poppins Italic",
		value: "Poppins Italic",
		weight: ["100", "300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Poppins",
		value: "Poppins",
		weight: ["100", "300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Prompt Italic",
		value: "Prompt Italic",
		weight: ["100", "300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Prompt",
		value: "Prompt",
		weight: ["100", "300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Quicksand",
		value: "Quicksand",
		weight: ["300", "400", "500", "700"],
		distant: true,
	},
	{
		label: "Reem Kufi Fun",
		value: "Reem Kufi Fun",
		weight: ["400", "500", "700"],
		distant: true,
	},
	{
		label: "Righteous",
		value: "Righteous",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Roboto Italic",
		value: "Roboto Italic",
		weight: ["100", "300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Roboto",
		value: "Roboto",
		weight: ["100", "300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Roboto Slab",
		value: "Roboto Slab",
		weight: ["100", "300", "400", "500", "700", "900"],
		distant: true,
	},
	{
		label: "Shadows Into Light",
		value: "Shadows Into Light",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Shrikhand",
		value: "Shrikhand",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Solitreo",
		value: "Solitreo",
		weight: ["400"],
		distant: true,
	},
	{
		label: "Sono",
		value: "Sono",
		weight: ["300", "400", "500", "700"],
		distant: true,
	},
	{
		label: "Special Elite",
		value: "Special Elite",
		weight: ["400"],
		distant: true,
	},
];

export default GoogleFonts;
