import React, { useContext, useState } from "react";
import { ThemeContext } from "../../contexts/Theme/ThemeContext";
import { ThemeContextType } from "../../contexts/Theme/types";
import InputSLider, { InputSliderProps } from "react-input-slider";

interface ISlider extends InputSliderProps {
	value: number;
	title?: string;
	className?: string;
	hideondrag?: string;
	hidebreakpoint?: number;
}

const Slider: React.FC<ISlider> = (props: ISlider) => {
	const { isDarkMode } = useContext<ThemeContextType>(ThemeContext);
	const [isDragging, setIsDragging] = useState(false);
	const hideElem: any = props.hideondrag
		? document.querySelector(props.hideondrag)
		: false;

	let shouldHide = props.hidebreakpoint
		? document.body.clientWidth < props.hidebreakpoint
		: false;

	const sliderStyle = {
		thumb: {
			width: "10px",
			height: "10px",
			backgroundColor: "var(--slider-thumb-color)",
			border: "none",
			boxShadow: "none",
		},

		active: {
			backgroundColor: "var(--slider-active-color)",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},

		track: {
			backgroundColor: isDarkMode ? "#2d2d2d" : "#ebebeb",
			width: "100%",
		},
	};

	const handleStart = (e: any) => {
		setIsDragging(true);
		if (hideElem && shouldHide) {
			hideElem.style.visibility = "hidden";
			hideElem.style.transitionDuration = "0s";
		}
	};

	const handleEnd = (e: any) => {
		setIsDragging(false);
		if (hideElem && shouldHide) {
			hideElem.style.visibility = "unset";
			hideElem.style.transition = null;
		}
	};

	return (
		<div
			className={
				isDragging && hideElem && shouldHide
					? " visible opacity-70 bg-opacity-50 bg-neutral-100 dark:bg-neutral-900 p-2 rounded-md mb-[-0.70rem] duration-300s"
					: ""
			}
		>
			<div
				className={
					"flex justify-between text-sm" +
					(props.className ? " " + props.className : "")
				}
			>
				<span>{props.title || "Slider"}</span>
				<span>
					<input
						className="bg-neutral-100 dark:bg-neutral-800 rounded-md pr-1"
						style={{ width: "60px", direction: "rtl" }}
						type="number"
						value={props.value}
						min={props.xmin}
						max={props.xmax}
						step={props.xstep}
						onChange={(e) =>
							props.onChange
								? props.onChange({
										x: Number(e.target.value),
										y: 0,
								  })
								: undefined
						}
					/>
				</span>
			</div>
			<InputSLider
				x={props.value}
				xmin={1}
				xmax={100}
				xstep={1}
				styles={sliderStyle}
				onDragStart={handleStart}
				onDragEnd={handleEnd}
				{...props}
			/>
		</div>
	);
};

export default Slider;
