import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLock,
	faLockOpen,
	faPen,
	faPlusCircle,
	faRotate,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./Image.scss";
import { t } from "i18next";

interface IImageProps {
	className?: string;
	rotate?: boolean;
	onRemove?: any;
	onChange?: any;
	onLock?: any;
	onRotate?: any;
	isLocked?: boolean;
	image?: string;
	rotation?: number;
}

const Image: React.FC<IImageProps> = (props) => {
	const handleImageChange = (e: any) => {
		let file = e.target.files[0];
		let reader = new FileReader();

		reader.readAsDataURL(file);
		reader.onloadend = () => {
			e.target.value = null;
			let res = reader.result?.toString();
			if (!res) return false;
			if (props.onChange) props.onChange(res);
		};
	};

	const handleLock = (e: any) => {
		e.preventDefault();
		e.stopPropagation();

		if (props.onLock) props.onLock();
	};

	const rotate = () => {
		if (props.onRotate) props.onRotate();
	};

	const classList = [
		"Image flex items-center justify-center aspect-square drop-shadow-xl",
		props.image?.length ? "hasImage" : "gen-opacity-0",
		props.className,
	].join(" ");
	return (
		<div className={classList}>
			<div
				className="z-0 absolute top-0 left-0"
				style={{
					aspectRatio: "1",
					backgroundPosition: "center",
					backgroundSize: "cover",
					backgroundImage: `url(${props.image})`,
					width: "100%",
					height: "100%",
					transform: `rotate(${props.rotation}deg)`,
				}}
			></div>
			<button
				onClick={handleLock}
				className="lock-btn absolute text-white z-20 flex gen-hide items-center"
				title={t(props.isLocked ? "unlock" : "lock").toString()}
			>
				{!props.isLocked && props.image && (
					<>
						<FontAwesomeIcon
							icon={props.isLocked ? faLock : faLockOpen}
						/>
						{t("lock")}
					</>
				)}
			</button>
			{props.onRemove && !props.isLocked && props.image && (
				<button
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						props.onRemove();
					}}
					className="delete-btn absolute bottom-6 !top-auto left-6 text-white z-20 flex gap-4 gen-hide"
				>
					<FontAwesomeIcon icon={faTrash} />
				</button>
			)}
			{props.rotate !== false && !props.isLocked && props.image && (
				<button
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						rotate();
					}}
					className="rotate-btn absolute top-6 right-6 text-white z-20 flex gap-4 gen-hide"
				>
					<FontAwesomeIcon icon={faRotate} />
				</button>
			)}
			<div className="gen-hide z-10 overlay flex items-center justify-center">
				<FontAwesomeIcon
					className="upload-icon scale-50 hover:scale-75 duration-100 cursor-pointer"
					icon={
						props.isLocked
							? faLock
							: props.image?.length
							? faPen
							: faPlusCircle
					}
					onClick={(e) => {
						if (props.isLocked) handleLock(e);
					}}
				/>
			</div>
			{!props.isLocked && (
				<input
					className="file-input z-10"
					type="file"
					accept=".png, .jpeg"
					disabled={props.isLocked}
					onChange={handleImageChange}
				/>
			)}
		</div>
	);
};

export default Image;
