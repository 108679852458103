import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPause,
	faPlay,
	faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";

interface ISrcResult {
	title: string;
	imageUrl: string;
	artist?: string;
	onClick?: any;
	preview?: any;
	isPlaying?: any;
}

const SearchResult: React.FC<ISrcResult> = (props) => {
	return (
		<li className={"Result" + (props.isPlaying ? " playing" : "")}>
			<button
				onClick={props.onClick}
				className="w-full flex items-center gap-3 bg-white text-neutral-800 dark:text-neutral-100 dark:bg-neutral-800 dark:hover:bg-neutral-700 p-2 rounded-md text-md"
			>
				<span
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						if (props.preview) props.preview();
					}}
					className="flex items-center justify-center image rounded-md overflow-hidden bg-neutral-200 dark:bg-neutral-900"
					style={{
						backgroundImage: "url(" + props.imageUrl + ")",
						backgroundSize: "cover",
					}}
				>
					{
						<span className="play-btn justify-center w-full h-full flex items-center text-white">
							<FontAwesomeIcon
								className={
									!props.preview ? "text-sm opacity-50" : ""
								}
								icon={
									props.preview
										? props.isPlaying
											? faPause
											: faPlay
										: faVolumeXmark
								}
							/>
						</span>
					}
				</span>
				<span className="flex flex-col items-start text-start">
					<span
						className="title w-full text-ellipsis text-sm"
						title={props.title}
					>
						{props.title}
					</span>
					{props.artist && (
						<span
							className="text text-xs text-gray-500 text-ellipsis overflow-hidden whitespace-nowrap max-w-xs"
							title={props.artist}
						>
							{props.artist}
						</span>
					)}
				</span>
			</button>
		</li>
	);
};

export default SearchResult;
