import React, { useState } from "react";
import Search from "../Cont/Search/Search";
import Frame from "./components/Frame/Frame";
import Toolbar from "./components/Toolbar/Toolbar";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { IBGConfig } from "./components/Tools/Background/BackgroundConfig";
import { t } from "i18next";
import Settings from "../Settings/Settings";
import { IFontConfig } from "./components/Tools/TextConfig";

export interface IState {
	background?: string;
	backgroundImg?: string;
	bgProps?: IBGConfig;
	bgType?: string;
	isBgLocked?: boolean;
	image?: string;
	isImgLocked?: boolean;
	imgRotation?: number;
	title?: string;
	text?: string;
	more?: string;
	titleProp?: IFontConfig;
	textColor?: string;
	textProp?: IFontConfig;
	item?: any;
	musicTag?: boolean;
}

export interface IStateProps {
	state: IState;
	setState: (vals: IState) => void;
}

export const getDefaultConf = () => ({
	background:
		"radial-gradient(circle at right top, rgb(0, 255, 244) 0%, rgb(3, 133, 217) 44%, rgb(255, 0, 112) 99%)",
	bgType: "gradient",
	title: t("State.title").toString(),
	text: t("State.text").toString(),
	textColor: "#ffffff",
	musicTag: false,
	titleProp: {
		fontFamily: "Poppins",
		fontWeight: "300",
	},
	textProp: {
		fontFamily: "Poppins",
		fontWeight: "300",
		fontSize: "3.7",
	},
});

const Builder: React.FC<any> = (props: any) => {
	const prev_conf = JSON.parse(localStorage.getItem("conf") ?? "{}");

	const [State, setState] = useState<IState>({
		...getDefaultConf(),
		...prev_conf,
	});

	const [DrawerState, setDrawerState] = useState({
		isOpen: true,
		children: false,
	});

	const saveState = (state: IState) => {
		setState((prev) => {
			localStorage.setItem(
				"conf",
				JSON.stringify({
					background: state.background ?? prev.background,
					backgroundImg: state.backgroundImg ?? prev.backgroundImg,
					bgType: state.bgType ?? prev.bgType,
					bgProps: state.bgProps ?? prev.bgProps,
					isBgLocked: state.isBgLocked ?? prev.isBgLocked,
					titleProp: state.titleProp ?? prev.titleProp,
					textProp: state.textProp ?? prev.textProp,
					textColor: state.textColor ?? prev.textColor,
					musicTag: state.musicTag ?? prev.musicTag,
				})
			);
			return state;
		});
	};

	const handleSearchSelect = (item: any) => {
		saveState({
			...State,
			title: item.title,
			text: item.text,
			item: item,
			bgProps: State.isBgLocked
				? State.bgProps
				: {
						blur: State.bgProps?.blur,
						posX: 0,
						posY: 0,
						size: 1,
				  },
			backgroundImg: State.isBgLocked ? State.backgroundImg : item.image,
			bgType: State.isBgLocked ? State.bgType : "image",
			image: State.isImgLocked ? State.image : item.image,
			imgRotation: State.isImgLocked ? State.imgRotation : 0,
		});
	};

	return (
		<div
			className={`App text-black dark:text-white bg-gray-100 dark:bg-neutral-800`}
		>
			<Toolbar
				state={State}
				setState={saveState}
				drawerState={DrawerState}
				setDrawerState={setDrawerState}
			/>
			<Drawer
				direction="left"
				open={DrawerState.isOpen}
				enableOverlay={false}
				style={{
					width: "300px",
					padding: "1rem",
					backgroundColor: "black",
				}}
				className="Drawer flex flex-col gap-6  dark:text-white !p-0"
			>
				<div className="flex flex-col py-4 overflow-hidden h-svh p-4 bg-gray-200 dark:bg-neutral-900 gap-4">
					<button
						onClick={() =>
							setDrawerState({ isOpen: false, children: false })
						}
						className="text-left flex items-center gap-3"
					>
						<FontAwesomeIcon icon={faChevronLeft} />
						{t("close")}
					</button>
					<Search
						onItemClick={handleSearchSelect}
						initialValue={props.fromShare?.title}
						endpoint={props.fromShare?.endpoint}
						onClose={() =>
							setDrawerState({ isOpen: false, children: false })
						}
					/>
				</div>
			</Drawer>
			<Frame state={State} setState={saveState} />
			<div className="fixed right-4 bottom-2 z-50">
				<Settings />
			</div>
		</div>
	);
};

export default Builder;
