import { ClassNamesConfig } from "react-select";
import { StylesConfig } from "react-select";

export const reactSelectStyles: StylesConfig = {
	control: (provided, state) => ({
		...provided,
		backgroundColor: state.selectProps.menuIsOpen ? "#1f2937" : "#374151",
		borderColor: state.selectProps.menuIsOpen ? "#fff" : "#e5e7eb",
		boxShadow: state.selectProps.menuIsOpen ? "none" : "none",
		"&:hover": {
			borderColor: state.selectProps.menuIsOpen ? "#fff" : "#e5e7eb",
		},
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected
			? "#1f2937"
			: state.isFocused
			? "#374151"
			: "#1f2937",
		color: state.isSelected ? "#fff" : "#fff",
		"&:hover": {
			backgroundColor: "#1f2937",
		},
	}),
	menu: (provided, state) => ({
		...provided,
		backgroundColor: "#1f2937",
	}),
	singleValue: (provided, state) => ({
		...provided,
		color: "#fff",
	}),
};

export const reactSelectClasses: ClassNamesConfig = {
	singleValue: (state) => "dark:!text-white",
	control: (state) =>
		"!bg-neutral-100 dark:!bg-neutral-800 !border-none !text-white !transition-none",
	menu: (state) => "dark:!bg-neutral-800 !border-none dark:!text-white",
	option: (state) =>
		state.isSelected
			? "!bg-blue-500 dark:!text-white"
			: "dark:!bg-neutral-800 dark:!text-white hover:dark:!bg-cyan-800",
	input: () => "dark:!text-white !text-black",
};
