import React, { useState } from "react";
import Select, { StylesConfig } from "react-select";
import { OptionProps } from "react-select/dist/declarations/src/components/Option";
import { reactSelectClasses } from "../Select/Select";
import { FontFamillies, IFont } from "./fontLoader";
import "./GoogleFonts.css";

interface IFontSelectorProps {
	onChange: any;
	initialValue?: IFont | undefined;
}

const FontSelector: React.FC<IFontSelectorProps> = (props) => {
	const { onChange, initialValue } = props;
	const [selectedOption, setSelectedOption] = useState<IFont | undefined>(
		initialValue || undefined
	);

	const handleChange = (option: any) => {
		setSelectedOption(option);
		if (onChange) onChange(option);
	};

	return (
		<Select
			value={selectedOption}
			onChange={handleChange}
			options={FontFamillies}
			placeholder="Select font"
			classNames={reactSelectClasses}
			menuPlacement="auto"
			components={{ Option: FontOption }}
			styles={SelectStyle}
			isSearchable={true}
		/>
	);
};

const SelectStyle: StylesConfig = {
	singleValue: (provided, state: any) => {
		return {
			...provided,
			fontFamily: state.data.value || "",
		};
	},
};

const FontOption: React.FC<OptionProps> = (props: OptionProps) => {
	const { innerProps, isSelected, children } = props;

	return (
		<div
			{...innerProps}
			className={
				"p-1 " +
				(isSelected
					? "bg-sky-600 dark:!bg-sky-800 dark:!text-white"
					: "dark:!bg-neutral-800 dark:!text-white hover:!bg-neutral-200 hover:dark:!bg-neutral-700")
			}
			style={{
				fontFamily: children?.toString(),
			}}
		>
			{children}
		</div>
	);
};

export default FontSelector;
