import React, { createContext, useEffect, useState } from "react";

interface ThemeContextType {
	isDarkMode: boolean;
	toggleDarkMode: () => void;
}

interface CtxProps {
	children: React.ReactNode;
	// Ajoutez ici les autres props de votre composant
}

export const ThemeContext = createContext<ThemeContextType>({
	isDarkMode: false,
	toggleDarkMode: () => {},
});

export const ThemeContextProvider: React.FC<CtxProps> = ({ children }) => {
	const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
		// Récupère les préférences du navigateur
		const browserPref = window.matchMedia(
			"(prefers-color-scheme: dark)"
		).matches;
		// Récupère la valeur stockée dans le localstorage (si elle existe)
		const localPref = JSON.parse(
			localStorage.getItem("isDarkMode") || "null"
		);
		// Retourne la préférence du navigateur si elle existe, sinon la valeur stockée, sinon false par défaut
		return localPref ?? browserPref ?? false;
	});

	useEffect(() => {
		// Enregistre la valeur actuelle dans le localstorage
		localStorage.setItem("isDarkMode", JSON.stringify(isDarkMode));
		// Met à jour la classe du body en fonction de la valeur actuelle
		if (isDarkMode) {
			document.querySelector("body")?.classList.add("dark");
		} else {
			document.querySelector("body")?.classList.remove("dark");
		}
	}, [isDarkMode]);

	const toggleDarkMode = () => {
		setIsDarkMode(!isDarkMode);
	};

	const value = {
		isDarkMode,
		toggleDarkMode,
	};

	return (
		<ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
	);
};
