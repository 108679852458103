import { faSlash, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IToolbar } from "../Toolbar/Toolbar";
import { t } from "i18next";

const MusicTagConfig: React.FC<IToolbar> = (props) => {
	const { state, setState } = props;

	return (
		<div
			onClick={() =>
				setState({
					...state,
					musicTag: !state.musicTag,
				})
			}
			data-tooltip-id="toolbar-tooltip"
			data-tooltip-content={t("Tools.musicTag").toString()}
		>
			<span
				className="fa-stack"
				style={{
					width: "1.5rem",
					height: "1.5rem",
				}}
			>
				{!state.musicTag && (
					<FontAwesomeIcon icon={faSlash} className="fa-stack-1x" />
				)}
				<FontAwesomeIcon icon={faTag} className="fa-stack-1x" />
			</span>
		</div>
	);
};

export default MusicTagConfig;
