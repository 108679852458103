import domtoimage from "dom-to-image-more";
import { t } from "i18next";

const Save = async (state: any) => {
	const frame = document.querySelector(".Frame");
	if (!frame) return false;

	let dom = document.createElement("div");

	dom.classList.add("Frame", "generated-frame");
	dom.style.width = "1080px";
	dom.style.height = "1920px";
	dom.style.aspectRatio = "9/16";
	dom.append(frame.cloneNode(true));
	document.body.append(dom);

	let overlay = Overlay();

	domtoimage
		.toPng(dom, {
			bgcolor: "transparent",
		})
		.then((resp) => {
			let a = document.createElement("a");
			a.href = resp;
			a.download = (state?.title || t("State.title")) + ".png";
			window.document.body.appendChild(a);
			a.click();
			setTimeout(function () {
				if (a) document.body.removeChild(a);
				if (dom) document.body.removeChild(dom);
				overlay.remove();
			}, 0);
		});
};

const Overlay = () => {
	let ov = document.createElement("div");
	ov.classList.add(
		"SaveOverlay",
		"bg-neutral-200",
		"bg-opacity-50",
		"dark:bg-opacity-50",
		"dark:bg-neutral-800"
	);
	ov.innerHTML = `
		<div class="cont bg-neutral-200 dark:bg-neutral-700 p-4 rounded-md text-neutral-700 dark:text-neutral-200">${t(
			"saving"
		)}</div>
	`;
	document.body.append(ov);
	return ov;
};

export default Save;
