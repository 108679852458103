import React, { useState } from "react";
import "./Tabs.scss";

interface ITabsProps {
	childs: ITab[];
	props?: any;
	activeIndex?: number;
}

interface ITabsChildsProps extends ITabsProps {
	active: ITab | undefined;
	setActive?: (val: ITab) => void;
	props?: any;
}

interface ITab {
	id: string;
	index?: number;
	title: string;
	component: any;
	onChange?: () => void;
	props?: any;
	disabled?: boolean;
	tabTitle?: string | any;
	className?: string;
}

const Tabs: React.FC<ITabsProps> = (props) => {
	const { childs } = props;
	const [active, setActive] = useState<ITab | undefined>(
		childs[props.activeIndex || 0] || undefined
	);

	React.useEffect(() => {
		if (
			typeof props.activeIndex !== "undefined" &&
			active?.index !== props.activeIndex
		)
			setActive(childs[props.activeIndex || 0] || undefined);
	}, [props.activeIndex, childs, active]);

	const handleActive = (item: ITab) => {
		if (item.onChange) {
			item.onChange();
		}
		setActive(item);
	};

	return (
		<div className="Tabs text-black dark:text-white dark:bg-neutral-900">
			{props.childs?.length > 1 && (
				<Header {...props} setActive={handleActive} active={active} />
			)}
			<Body {...props} active={active} props={props.props} />
		</div>
	);
};

const Header: React.FC<ITabsChildsProps> = (props) => {
	const { active, setActive } = props;
	return (
		<div className="tabs-header dark:bg-neutral-800">
			{props.childs.map((tab: ITab, index: number) => {
				return (
					<button
						key={tab.id}
						className={
							"tabs-header-label text-neutral-500 dark:text-neutral-300" +
							(active?.id === tab.id
								? " active dark:!bg-neutral-900 dark:!text-white"
								: "")
						}
						disabled={tab.disabled}
						title={tab.tabTitle || tab.title}
						onClick={() =>
							setActive && setActive({ ...tab, index })
						}
					>
						{tab.title}
					</button>
				);
			})}
		</div>
	);
};

const Body: React.FC<ITabsChildsProps> = (props) => {
	const { active } = props;

	return (
		<div
			className={
				"tabs-body" + (active?.className ? " " + active.className : "")
			}
		>
			{active && (
				<active.component
					key={active.id}
					{...active}
					{...props.props}
					{...active.props}
				/>
			)}
		</div>
	);
};

export default Tabs;
