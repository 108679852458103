import React from "react";
import { t } from "i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const customAlert = (props: any) => {
	const { onOk, onCancel, onDraft } = props;

	let prom = new Promise((resolve, reject) => {
		toast(
			<CloseEditToast
				onOk={() => resolve(onOk ? onOk() : reject(false))}
				onCancel={() => resolve(onCancel ? onCancel() : resolve(true))}
				onDraft={
					onDraft
						? () => resolve(onDraft ? onDraft() : resolve(true))
						: false
				}
			>
				<b>{t("ResetToast.title")}</b>
				<span className="text-sm">{t("ResetToast.text")}</span>
			</CloseEditToast>,
			{
				autoClose: false,
				draggable: false,
				toastId: props.toastId,
				className: "dark:bg-neutral-900 dark:text-white",
			}
		);
		return false;
	});
	return prom;
};

export const CloseEditToast: React.FC<any> = (props: any) => {
	const { onOk, onCancel, onDraft } = props;

	function handleDraft() {
		if (onDraft) onDraft();
	}

	function handleCancel() {
		if (onCancel) onCancel();
	}

	function handleOk() {
		if (onOk) onOk();
	}

	return (
		<div className="CloseEditToast flex flex-col">
			{props.children}
			<div className="flex gap-2 flex-wrap mt-2 align-items-center justify-content-center">
				{onOk && (
					<div>
						<button
							className="bg-red-500 text-white rounded-md px-5"
							onClick={() => handleOk()}
						>
							{t("ResetToast.reset")}
						</button>
					</div>
				)}
				{onDraft && (
					<div>
						<button
							className="btn-yellow"
							onClick={() => handleDraft()}
						>
							{t("ResetToast.save")}
						</button>
					</div>
				)}
				{onCancel && (
					<div>
						<button
							className="bg-green-500 text-white rounded-md px-5"
							onClick={() => handleCancel()}
						>
							{t("ResetToast.cancel")}
						</button>
					</div>
				)}
			</div>
		</div>
	);
};
