import React from "react";
import ReactGPicker from "react-gcolor-picker";
import { IPropsMain } from "react-gcolor-picker/dist/components/Colorpicker/types";
import "./GPicker.scss";

interface IGPickerProps extends IPropsMain {
	theme?: string;
}

const GPicker: React.FC<IGPickerProps> = (props) => {
	return (
		<div
			className={
				"CustomGPicker" + (props?.theme === "dark" ? " dark" : "")
			}
		>
			<ReactGPicker {...props} />
		</div>
	);
};

export default GPicker;
