import React, { useContext, useState } from "react";
import { IToolbar } from "../../Toolbar/Toolbar";
import "./BackgroundConfig.scss";
import Slider from "react-input-slider";
import FCSlider from "../../../../Slider/Slider";
import GPicker from "../../GPicker/GPicker";
import Tabs from "../../../../Tabs/Tabs";
import { ThemeContextType } from "../../../../../contexts/Theme/types";
import { ThemeContext } from "../../../../../contexts/Theme/ThemeContext";
import Image from "../../Frame/Image/Image";
import { t } from "i18next";
import { IStateProps } from "../../../Builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";

export interface IBGConfig {
	size?: number;
	blur?: number;
	posX?: number;
	posY?: number;
}

const BackgroundConfig: React.FC<IToolbar> = (props) => {
	const { state } = props;

	let style: any = {};
	if (state.background?.indexOf("rgb") === 0)
		style.backgroundColor = state.background;
	else {
		if (state.bgType === "image") {
			style.backgroundImage = `url(${state.backgroundImg})`;
			style.backgroundSize = "cover";
			style.backgroundPosition = "center";
		} else style.backgroundImage = state.background;
	}

	return (
		<div
			className="BackgroundColor"
			style={{
				height: "100%",
				width: "20px",
			}}
		>
			<div
				data-tooltip-id="toolbar-tooltip"
				data-tooltip-content={
					props.isActive ? "" : t("Tools.bgColor").toString()
				}
				className="flex color-preview rounded-full aspect-square outline outline-1 outline-neutral-300 dark:outline-neutral-700"
				style={style}
			></div>
		</div>
	);
};

export const BackgroundPopper: React.FC<IToolbar> = (props: any) => {
	const { state, setState } = props;

	const handleChangeType = (val: string) => {
		setState({
			...state,
			bgType: val,
		});
	};

	const getActive = () => {
		if (state.bgType === "gradient") return 1;
		if (state.bgType === "image") return 2;
		return 0;
	};

	return (
		<Tabs
			childs={[
				{
					id: "color",
					title: t("Background.color"),
					component: ColorBg,
					className: "ColorTab",
					onChange: () => handleChangeType("color"),
					tabTitle: t("Background.colorTabTitle"),
				},
				{
					id: "gradient",
					title: t("Background.gradient"),
					component: GradientBG,
					className: "ColorTab",
					onChange: () => handleChangeType("gradient"),
					tabTitle: t("Background.colorTabTitle"),
				},
				{
					id: "image",
					title: t("Background.image"),
					component: CoverBG,
					onChange: () => handleChangeType("image"),
					tabTitle: t("Background.imgTabTitle"),
				},
			]}
			props={{
				state,
				setState,
			}}
			activeIndex={getActive()}
		/>
	);
};

const ColorBg: React.FC<IStateProps> = (props) => {
	const { state, setState } = props;
	const { isDarkMode } = useContext<ThemeContextType>(ThemeContext);

	const handleColorChange = (val: string) => {
		setState({
			...state,
			background: val,
			bgType: "color",
		});
	};

	return (
		<GPicker
			value={
				state.background ||
				"linear-gradient(180deg, rgb(0, 198, 251) 0.00%,rgb(0, 91, 234) 100.00%)"
			}
			gradient={false}
			onChange={handleColorChange}
			theme={isDarkMode ? "dark" : ""}
		/>
	);
};

const GradientBG: React.FC<IStateProps> = (props) => {
	const { state, setState } = props;
	const { isDarkMode } = useContext<ThemeContextType>(ThemeContext);

	const handleColorChange = (val: string) => {
		setState({
			...state,
			background: val,
			bgType: "gradient",
		});
	};

	return (
		<GPicker
			value={
				state.background ||
				"linear-gradient(180deg, rgb(0, 198, 251) 0.00%,rgb(0, 91, 234) 100.00%)"
			}
			solid={false}
			gradient={true}
			onChange={handleColorChange}
			theme={isDarkMode ? "dark" : ""}
		/>
	);
};

const CoverBG: React.FC<IStateProps> = (props) => {
	const { state, setState } = props;
	const { isDarkMode } = useContext<ThemeContextType>(ThemeContext);

	const changeBlur = (e: any) => {
		setState({
			...state,
			bgProps: {
				...state.bgProps,
				blur: e.x >= 0 ? e.x : e.target?.value,
			},
		});
	};

	const changeSize = (e: any) => {
		setState({
			...state,
			bgProps: {
				...state.bgProps,
				size: parseInt(e.x >= 0 ? e.x : e.target?.value),
			},
		});
	};

	const sliderStyle = {
		thumb: {
			width: "10px",
			height: "10px",
			backgroundColor: "var(--slider-thumb-color)",
			border: "none",
			boxShadow: "none",
		},

		active: {
			backgroundColor: "var(--slider-active-color)",
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},

		track: {
			backgroundColor: isDarkMode ? "#2d2d2d" : "#ebebeb",
			width: "100%",
		},
	};

	return (
		<div className="p-2 flex flex-col gap-4">
			<Image
				rotate={false}
				onChange={(res: any) =>
					setState({
						...state,
						bgProps: state.isBgLocked
							? state.bgProps
							: {
									blur: 50,
									posX: 0,
									posY: 0,
									size: 1,
							  },
						backgroundImg: state.isBgLocked
							? state.backgroundImg
							: res,
						bgType: state.isBgLocked ? state.bgType : "image",
					})
				}
				onLock={() => {
					setState({
						...state,
						isBgLocked: !state.isBgLocked,
					});
				}}
				onRemove={() => {
					setState({
						...state,
						backgroundImg: "",
					});
				}}
				isLocked={state.isBgLocked}
				image={state.backgroundImg}
				className="mx-auto"
			/>
			{state.backgroundImg && state.backgroundImg?.length > 0 ? (
				<>
					<div className="blurred-conf">
						<FCSlider
							hidebreakpoint={1000}
							title={t("Background.blur").toString()}
							value={state.bgProps?.blur ?? 50}
							xmin={0}
							xmax={100}
							xstep={1}
							styles={sliderStyle}
							onChange={changeBlur}
							hideondrag={".ToolbarPopper"}
						/>
					</div>
					<div className="size-conf">
						<FCSlider
							hidebreakpoint={1000}
							title={t("Background.size").toString()}
							value={state.bgProps?.size ?? 50}
							xmin={1}
							xmax={100}
							xstep={1}
							styles={sliderStyle}
							onChange={changeSize}
							hideondrag={".ToolbarPopper"}
						/>
					</div>
					<div>
						<div className="flex justify-between items-center">
							<span>{t("Background.pos")}</span>
							<button
								onClick={() => {
									setState({
										...state,
										bgProps: {
											...state.bgProps,
											posX: 0,
											posY: 0,
										},
									});
								}}
								title={"Reset"}
							>
								<FontAwesomeIcon
									className="text-sm"
									icon={faRotateLeft}
								/>
							</button>
						</div>
						<BGPos state={state} setState={setState} />
					</div>
				</>
			) : (
				<div className="text-neutral-500 text-center">
					{t("Background.noneSelected")}
				</div>
			)}
		</div>
	);
};

const BGPos: React.FC<IStateProps> = (props) => {
	const { state, setState } = props;
	const [Dragging, setDragging] = useState(false);
	const popper: any = document.querySelector(".ToolbarPopper");

	let shouldHide = document.body.clientWidth < 1000;

	const handleStart = () => {
		setDragging(true);
		if (popper && shouldHide) {
			popper.style.visibility = "hidden";
			popper.style.transitionDuration = "0s";
		}
	};

	const handleEnd = () => {
		setDragging(false);
		if (popper && shouldHide) {
			popper.style.visibility = "unset";
			popper.style.transition = null;
		}
	};

	return (
		<div
			className={
				"BgPosConfig bg-neutral-200 dark:bg-neutral-700 visible" +
				(Dragging && shouldHide ? " opacity-50" : "opacity-100")
			}
		>
			<Slider
				axis="xy"
				x={state.bgProps?.posX}
				y={state.bgProps?.posY}
				xmin={-1}
				xmax={1}
				xstep={0.001}
				ymin={-1}
				ymax={1}
				ystep={0.001}
				xreverse={true}
				yreverse={true}
				onDragStart={handleStart}
				onDragEnd={handleEnd}
				onChange={(pos: any) => {
					setState({
						...state,
						bgProps: {
							...state.bgProps,
							posX: pos.x,
							posY: pos.y,
						},
					});
				}}
				styles={{
					thumb: {
						backgroundColor: "var(--slider-active-color)",
					},
				}}
			/>
		</div>
	);
};

export default BackgroundConfig;
